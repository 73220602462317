<template>
  <div class="news_wrap">
    <div class="bg_c">
      <div class="img_bg">
        <div class="img_title">
          <h3>国美家咨询早知道</h3>
          <h6>价值共创,实现生态共赢</h6>
        </div>
      </div>
    </div>
    <!-- -------------------------------- -->
    <div class="gome_news">
      <div class="left_card">
        <h3 class="left_card-title">新闻中心</h3>
        <div class="bottom_line"></div>
      </div>
      <div class="right_card">
        <div class="gome_news-content">
          <div
            class="gome_news-content-con"
            v-for="(item, index) in items"
            :key="item.idx"
            :class="{ selected: activeIndex === index }"
            @click="toNewsDetail(item.idx)"
          >
            <img :src="item.image" alt="" />

            <div class="gome_news-content-con-title">
              <h3 class="gome_news-content-con-title-name">{{ item.name }}</h3>
              <p class="gome_news-content-con-title-des">{{ item.des }}</p>
              <h2 class="gome_news-content-con-title-time">{{ item.time }}</h2>
            </div>
            <p class="gome_news-content-con-more">
              阅读全部>
            </p>
          </div>
        </div>
      </div>
      <div class="gome_news-more" v-if="items.length > 6" @click="enter_news">
        查看更多
      </div>
    </div>
    <!-- -------------------------------- -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          idx: "1",
          image: require("@/assets/img/news_img1.png"),
          name: "让蓉城人民再“安逸”...",
          des:
            "近年来，向来以“安逸”著称的成都，正以前所未有的速度快速发展，在国内经济发展浪潮中...",
          date: "2021.08.23",
        },
        {
          idx: "2",
          image: require("@/assets/img/news_img3.png"),
          name: "重庆沙坪坝区副区长...",
          des:
            "2021年10月20日 重庆沙坪坝区副区长代建红、国际物流枢纽园区董事长罗书权一行拜会...",
          date: "2021.10.20",
        },
      ],
      activeIndex: -1,
    };
  },
  methods: {
    enter_news() {},

    toNewsDetail(idx) {
      this.$router.push(`/newsDetail${idx}`);
    },

    handleSelect(idx) {
      this.activeIndex = idx;
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
  padding: 0;
}
.news_wrap {
  width: 100%;
  overflow-x: auto;
  .bg_c {
    background: url("~@/assets/img/bg_news.png") no-repeat center 0;
    min-width: 1200px;
    .img_bg {
      width: 1200px;
      height: 800px;
      margin: auto;
      display: flex;
      align-items: center;
      .img_title {
        width: 756px;
        height: 155px;
        margin: auto;
      }
      .img_title h3 {
        height: 98px;
        font-size: 70px;
        color: #ffffff;
        text-align: center;
        opacity: 1;
        letter-spacing: 15.56px;
      }
      .img_title h6 {
        width: 756px;
        height: 37px;
        font-size: 26px;
        color: #ffffff;
        text-align: center;
        opacity: 1;
        letter-spacing: 10px;
        margin-top: 20px;
      }
    }
  }
  .gome_news {
    width: 1200px;
    margin: auto;
    padding-bottom: 50px;
    position: relative;
    .left_card {
      padding-top: 100px;
      padding-bottom: 98px;
      opacity: 1;
      &-title {
        font-size: 40px;
        font-family: Hiragino Sans GB;
        font-weight: 600;
        line-height: 40px;
        text-align: center;
        color: #1f2329;
        opacity: 1;
      }
      .bottom_line {
        width: 60px;
        height: 8px;
        background: linear-gradient(to right, #f6ba4f, #ea8427);
        border-radius: 4px;
        opacity: 1;
        text-align: bottom;
        margin: auto;
        position: relative;
        top: 10px;
      }
    }
    .right_card {
      padding-top: 0px;
      max-height: 1877px;
      .gome_news-content {
        display: flex;
        width: 1230px;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        margin: auto;
        &-con {
          width: 380px;
          height: 523px;
          margin-right: 30px;
          top: 0;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          background-color: #fafafa;
          border-radius: 10px;
          margin-bottom: 50px;
          cursor: pointer;
          transition: all 0.1s ease-in-out;

          &:hover {
            box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
            top: -16px;
          }

          &.selected {
            box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
            top: -16px;
          }

          img {
            width: 380px;
            height: 201px;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
          }
          &-more {
            font-size: 18px;
            position: absolute;
            color: #eb8729;
            bottom: 26px;
            left: 30px;
            cursor: pointer;
          }
          .gome_news-content-con-title {
            display: block;
            width: 100%;
            position: absolute;
            bottom: 97px;
            left: 0;
            margin-top: 40px;
            padding: 0 20px;
            box-sizing: border-box;
            &-name {
              width: 100%;
              opacity: 1;
              font-size: 30px;
              font-family: PingFang SC;
              font-weight: 600;
              color: #1f2329;
              white-space: nowrap; /*把文本强制显示在一行*/
              text-overflow: ellipsis; /*超出部分显示...*/
            }
            &-des {
              box-sizing: border-box;
              height: 74px;
              opacity: 1;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 24px;
              color: #666666;
              margin-top: 16px;
              margin-bottom: 16px;
            }
            &-time {
              height: 19px;
              opacity: 1;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 26px;
              color: #666666;
            }
          }
        }
      }
    }
  }
  .gome_news-more {
    margin: auto;
    width: 280px;
    bottom: 100px;
    position: relative;
    border-radius: 4px;
    border: 1px solid #ea8528;
    text-align: center;
    font-size: 16px;
    font-family: PMicrosoft YaHei;
    font-weight: 400;
    line-height: 48px;
    color: #ea8528;
  }
}
</style>
